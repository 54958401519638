<template>
    <div>
    <ContentHeader title="Data Akun Keuangan" url="/data-akun-keuangan" subTitle="Tambah Data Akun Keuangan" />
     <div class="content-header">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col">
                    <div class="card">
                        <div class="card-header row d-flex align-items-center">
                            <h3 class="card-title col-6">Tambah Data Akun Keuangan</h3>
                        </div>
                        <div class="card-body px-2" v-loading="loading_screen">
                            <form class="pl-2" @submit.prevent="prosesAkunKeuangan">
                                <div class="row">
                                    <div class="col-lg-2"></div>
                                    <div class="col-lg-8">
                                        <div class="form-group">
                                            <label for="nomor_rekam_medis">Kelompok Akun</label>
                                            <select class="custom-select form-control" v-model="akun_keuangan.kelompok_akun_id">
                                                <option selected="" disabled="">- Pilih Kelompok Akun -</option>
                                                <option v-for="row in kelompok_akun" :key="row.id" :value="row.id">{{ row.nama }}</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label for="nomor_rekam_medis">Pos Akun</label>
                                            <select class="custom-select form-control" @change="changePosAkun(akun_keuangan.pos_akun_id)" v-model="akun_keuangan.pos_akun_id">
                                                <option selected="" disabled="">{{ select_label_pos_akun }}</option>
                                                <option v-for="row in pos_akun" :key="row.id" :value="row.id">{{ row.nama }}</option>
                                            </select>
                                        </div>
                                       <div class="form-group">
                                            <label for="nomor_rekam_medis">Saldo Normal</label>
                                            <input type="text" class="form-control" disabled="" v-model="akun_keuangan.saldo_normal">
                                        </div>
                                       <div class="form-group">
                                            <label for="nomor_rekam_medis">Kode Akun</label>
                                            <input type="text" class="form-control" disabled="" v-model="akun_keuangan.kode">
                                        </div>
                                        <div class="form-group">
                                            <label for="nomor_rekam_medis">Nama Akun</label>
                                            <input type="text" class="form-control" v-model="akun_keuangan.nama">
                                        </div>
                                        <div class="mt-4">
                                            <button type="submit" class="btn btn-primary d-flex align-items-center">
                                            <div>
                                                Submit
                                            </div>    
                                            <div>
                                                <svg v-if="loading" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style=" background: none; display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                                <g>
                                                    <path d="M50 15A35 35 0 1 0 74.74873734152916 25.251262658470843" fill="none" stroke="#ffffff" stroke-width="12"></path>
                                                    <path d="M49 3L49 27L61 15L49 3" fill="#ffffff"></path>
                                                    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                                                </g>

                                                </svg>
                                            </div>
                                            </button>
                                        </div>

                                    </div>
                                    <div class="col-lg-2"></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader2'

import { ref, onMounted, reactive, watch } from 'vue'
import axios from 'axios'
import router from '@/router'
import { useRoute } from 'vue-router'
import Swal from 'sweetalert2'

const route = useRoute()

const kelompok_akun = ref([])
const pos_akun_awal = ref([])
const pos_akun = ref([])
const select_label_pos_akun = ref('- Pilih Kelompok Akun Terlebih Dahulu -')
const akun_keuangan = reactive({
    "kelompok_akun_id" : null,
    "pos_akun_id" : null,
    "kode" : null,
    "nama" : null,
    "saldo_normal" : null,
    "posisi_laporan" : null
})
const loading = ref(false)
const loading_screen = ref(false)

onMounted(() => {
    getKelompokAkun()
    getPostAkun()
})

const getData = async() => {
    loading_screen.value = true
    await axios.get(`api/akun_keuangan/getData/${route.params.id}`)
    .then((response) => {
        akun_keuangan.kelompok_akun_id = response.data.kelompok_id
        akun_keuangan.pos_akun_id = response.data.pos_id
        akun_keuangan.kode = response.data.kode
        akun_keuangan.nama = response.data.nama
        akun_keuangan.saldo_normal = response.data.saldo_normal
        akun_keuangan.posisi_laporan = response.data.posisi_laporan
    })
    .finally(() => {
        loading_screen.value = false
    })
}

const getKelompokAkun = async () => {
    let { data } = await axios.get(`api/kelompok_akun`)

    kelompok_akun.value = data
}

const getPostAkun = async () => {
    await axios.get(`api/pos_akun`)
    .then((response) => {
        pos_akun_awal.value = response.data
        getData()
    })

}

// const changeKelompokAkun = (id) => {
//     pos_akun.value = []
//     for (var i = 0; i < pos_akun_awal.value.length; i++) {
//         if (pos_akun_awal.value[i].kelompok_id == id) {
//             pos_akun.value.push(pos_akun_awal.value[i])
//         }
//     }

//     if (pos_akun.value.length != 0) {
//         select_label_pos_akun.value = '- Pilih Pos Akun -'
//     } else {
//         select_label_pos_akun.value = '- Tidak Perlu Memilih Pos Akun'

//         for (var j = 0; j < kelompok_akun.value.length; j++) {
//             if (kelompok_akun.value[j].id == id) {
//                 akun_keuangan.saldo_normal = kelompok_akun.value[j].saldo_normal
//                 akun_keuangan.posisi_laporan = kelompok_akun.value[j].posisi_laporan
//             }
//         }

//         generateKodeAkunByKelompok_id(id)
//         console.log(akun_keuangan)

//     }
// }
watch(() => akun_keuangan.kelompok_akun_id, (id) => {
    if(id) {
        loading_screen.value = true
        pos_akun.value = []
        console.log(pos_akun_awal.value)
        for (var i = 0; i < pos_akun_awal.value.length; i++) {
            if (pos_akun_awal.value[i].kelompok_id == id) {
                pos_akun.value.push(pos_akun_awal.value[i])
            }
        }
    
        if (pos_akun.value.length != 0) {
            select_label_pos_akun.value = '- Pilih Pos Akun -'
        } else {
            select_label_pos_akun.value = '- Tidak Perlu Memilih Pos Akun'
    
            for (var j = 0; j < kelompok_akun.value.length; j++) {
                if (kelompok_akun.value[j].id == id) {
                    akun_keuangan.saldo_normal = kelompok_akun.value[j].saldo_normal
                    akun_keuangan.posisi_laporan = kelompok_akun.value[j].posisi_laporan
                }
            }
    
            generateKodeAkunByKelompok_id(id)    
        }
        loading_screen.value = false
    }
})

const changePosAkun = (id) => {
    for (var i = 0; i < pos_akun.value.length; i++) {
        if (pos_akun.value[i].id == id) {
            akun_keuangan.saldo_normal = pos_akun.value[i].saldo_normal
            akun_keuangan.posisi_laporan = pos_akun.value[i].posisi_laporan
        }
    }

    generateKodeAkunByPos_id(id)
}

const generateKodeAkunByKelompok_id = async (id) => {
    loading_screen.value = true
    let { data } = await axios.get(`api/akun_keuangan/getLastIdWhereKelompokId/${id}`)

    let kode_awal_kode = data.kode.slice(0, 2)
    let kode_baru = data.kode.slice(2, data.kode.length)
    
    kode_baru = `${kode_awal_kode}${parseInt(kode_baru) + 1}`
    akun_keuangan.kode = kode_baru
    loading_screen.value = false
}

const generateKodeAkunByPos_id = async (id) => {
    loading_screen.value = true
    let { data } = await axios.get(`api/akun_keuangan/getLastIdWherePosId/${id}`)

    let kode_awal_kode = data.kode.slice(0, 2)
    let kode_baru = data.kode.slice(2, data.kode.length)
    
    kode_baru = `${kode_awal_kode}${parseInt(kode_baru) + 1}`
    akun_keuangan.kode = kode_baru
    loading_screen.value = false
}

const prosesAkunKeuangan = () => {
    loading.value = true
    axios.post(`api/akun_keuangan/update/${route.params.id}`, akun_keuangan)
    .then(() => {
        Swal.fire({
            title: 'Berhasil!',
            text: 'Berhasil Mengubah Data',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
        })
        loading.value = false
        router.push('/data-akun-keuangan')
    })
    .catch(() => {
        Swal.fire({
            title: 'Gagal!',
            text: 'Gagal Mengubah data',
            icon: 'error',
            confirmButtonText: 'Ok'
        })  
        loading.value = false
    })
}


</script>

<style>
    
</style>